.reserve_totals_wrapper {
    padding: 0 !important;
}

.reserve_totals_wrapper input {
    padding: 0 6px !important;
}

.reserve_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}