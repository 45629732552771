.payment_modal_form_content {
  max-height: 90%;
  margin-bottom: 20px;
}

.payment_modal_form_content::-webkit-scrollbar {
  width: var(--t-scrollbar-width);
}

.payment_modal_form_content::-webkit-scrollbar-track {
  background-color: transparent;
}

.payment_modal_form_content::-webkit-scrollbar-thumb {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

.payment_modal_form_content::-webkit-scrollbar-thumb:hover {
  background-color: var(--t-secondary-background);
}
