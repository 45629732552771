.table_footer {
  display: flex;
  width: 100%;
}

.footer_column_left {
  width: 50%;
  text-align: right;
  padding: 10px;
}

.footer_column_right {
  width: 25%;
  text-align: right;
  padding: 10px;
}

.footer_error_message {
  width: 100%;
  text-align: center;
}
