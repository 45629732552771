/* Policy Quote Header */

.policy_quote_header_container {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
}

.policy_quote_header_pair_text_container {
  display: flex;
  padding-right: 15px;
  align-items: center;
}

.policy_quote_header_text_container {
  padding-right: 10px;
}

/* Policy Quote Bottom Buttons */

.policy_quote_bottom_buttons_container {
  display: flex;
  width: 100%;
  height: 70px;
}

.policy_quote_bottom_buttons_left_column {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.policy_quote_bottom_buttons_right_column {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.policy_quote_bottom_buttons_right_margin {
  margin-right: 15px;
}

/* Unbind Quote Modal */

.policy_quote_unbind_modal_body_container {
  display: flex;
  flex-direction: column;
}

.informative_text_unbind_quote {
  text-align: left !important;
  margin-bottom: 10px !important;
}

.warning_message_unbind_quote {
  display: flex !important;
  margin-bottom: 10px !important;
}

.warning_icon_unbind_quote_warning {
  align-self: center;
  margin: 0px 10px;
  color: var(--t-warning);
}

.warning_icon_unbind_quote_error {
  align-self: center;
  margin: 0px 10px;
  color: var(--t-danger);
}

/* Exposure and Premium  */

.exposure_and_premium_footer_container {
  display: flex;
  width: 100%;
  background-color: var(--t-secondary-background-50);
  padding-left: 16px;
  padding-right: calc(8px + 5%);
  justify-content: space-between;
}

.exposure_table_expanded_information_container {
  width: 100%;
}

.exposure_table_header_container_collapsed {
  display: flex;
  height: 31px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.exposure_table_header_container_expanded {
  display: flex;
  height: 31px;
  width: 100%;
  align-items: center;
}

.footer_header_description_container {
  display: flex;
  align-items: center;
  width: 20%;
}

.exposure_header_description_expanded {
  display: flex;
  align-items: center;
  width: 250px;
}

.footer_header_calculation_label_container {
  display: flex;
  align-items: center;
}

.footer_header_margin_left {
  margin-left: 20px;
}

.footer_header_calculation_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header_delete_state_container {
  display: flex;
  justify-content: flex-end;
  width: calc(5% - 30px);
}
