:global(.permissions_container) {
  width: 30%;
  margin-bottom: 10px;
}
:global(.permissions_title) {
  padding-left: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

:global(.permissions_section) {
  border: 1px solid var(--t-border-color);
  padding: 10px;
  min-height: 365px;
}

:global(.permissions_actions) {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:global(.permissions_checks) {
  overflow: auto;
  max-height: 205px;
}

:global(.permissions_checks .true_switch_container .MuiCheckbox-root) {
  padding: 5px;
}

:global(.permissions_checks::-webkit-scrollbar) {
  width: var(--t-scrollbar-width);
}

:global(.permissions_checks::-webkit-scrollbar-track) {
  background-color: transparent;
}

:global(.permissions_checks::-webkit-scrollbar-thumb) {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

:global(.permissions_checks::-webkit-scrollbar-thumb:hover) {
  background-color: var(--t-secondary-background);
}

:global(.command_section_container) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
