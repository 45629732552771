:global(div[class*="activity-section-container"]) {
  width: 100%;
  height: 100%;
}

:global(div[class*="activity-section-container"] .activity-container) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-header
  ) {
  background: transparent !important;
  margin-left: -1px;
  margin-top: -1px;
  margin-bottom: 0;
  border-top-right-radius: 4;
  border-top-left-radius: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-header
      .filters
  ) {
  background: transparent !important;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-header
      .filters
      .svg
  ) {
  color: var(--t-text-color);
  font-size: var(--t-title-size);
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-header
      .filters
      .svg:hover
  ) {
  cursor: pointer;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-stepper
  ) {
  flex-grow: 1;
  overflow: auto;
  height: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-stepper
      ul
  ) {
  margin: 0px;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-stepper
      .true_stepper_dots
  ) {
  background: transparent;
  border: 1px solid var(--t-primary);
  color: var(--t-text-color);
  margin: 0;
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-stepper
      .activity-item
  ) {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-stepper
      .activity-item
      > span
  ) {
  align-self: flex-start;
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-stepper
      .step-container
  ) {
  color: var(--t-text-color);
  font-size: var(--t-small-title-size);
}

:global(
    div[class*="activity-section-container"]
      .activity-container
      .activity-stepper
      svg
  ) {
  color: var(--t-primary);
  width: 14px;
  height: 14px;
  position: static;
}
