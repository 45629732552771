.quick_actions_container {
    margin: 0 10px;
    width: 100%;
}

.error_outline_outlined_icon {

    color: var(--t-secondary-text-color);
    font-size: 87.5px !important;
}

.new_submission_form_styles {
    padding: 0 16px;
    padding-bottom: 0;
    padding-top: 16px;
}

.show_new_submission {
    padding: 0 24px;
    padding-top: 24px;
}

.new_submission_table_title {
    margin-bottom: 16px;
}

.import_acord_upload_container{
    padding: 20px 0;
}