.insured_invoice_modal_footer_row {
  width: 100%;
  display: flex;
  font-weight: 700;
  height: 48px;
}

.totals_totals_column {
  width: 29% !important;
}

.footer_description_column {
  padding-left: 42px !important;
}

.edit_invoice_actions_container {
  display: flex;
  width: 100%;
  gap: 10px;
}

.edit_invoice_left_actions {
  width: 50%;
  text-align: left;
}

.edit_invoice_left_actions > button {
  margin-right: 30px;
}

.edit_invoice_right_actions {
  width: 50%;
  text-align: right;
}

.edit_invoice_right_actions > button {
  margin-left: 30px;
}

.invoice_tab_content {
  margin: 10px;
  height: 100%;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.detail_table_invoice_wrapper {
  flex-grow: 1;
}

.detail_table_invoice_wrapper
  #invoices_detail_multi_table
  .true_ui_base_table_container {
  height: 100%;
}
