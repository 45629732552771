.claim_landing_tabs_wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.claim_landing_tabs_container {
  margin-right: -63px;
  width: 100%;
}

:global(
    div[class*="claim_landing_tabs_wrapper"]
      .k-tabstrip.k-tabstrip-top
      .k-tabstrip-items-wrapper.k-hstack
  ) {
  padding-bottom: 5px;
  padding-top: 10px;
}
