:global(.notifications_drawer .MuiDrawer-paper) {
    top: 60px;
    left: 15%;
    right: 15%;
    height: auto;
    background-color: var(--t-primary-background);
}

:global(.notifications_drawer .notifications_content_container) {
    padding: 15px 15px 0px 15px;
    display: flex;
    flex-direction: column;
}

:global(.notifications_drawer .notifications_close_icon) {
    color: var(--t-text-color);
    font-size: var(--t-heading-5-size);
}

:global(.notifications_drawer .notifications_header_content) {
    display: flex;
    flex-direction: row;
    color: var(--t-text-color);
    width: 100%;
    justify-content: space-between;
}

:global(.notifications_drawer .notifications_content) {
    color: var(--t-text-color);
    width: 100%;
}