.billing_details_wrapper_row {
    padding-top: 15px;
}

.billing_details_footer {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 15px;
}

.billing_details_main_container {
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.billing_details_totals_footer_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.billing_details_totals_footer_item {
    margin-right: 15px;
}

.billing_details_totals_footer_space {
    margin-right: 45px;
}