:global(#batch_history_details) {
  flex: 1;
  overflow: hidden;
}

:global(#batch_history_details .true_table_wrapper) {
  height: auto;
}

:global(#payment_batch_preview) {
  flex: 1;
  overflow: hidden;
}

:global(#payment_batch_preview .true_table_wrapper) {
  height: auto;
}
