:global(.true_show_files_scrollable_container) {
    max-height: 80px;
    width: 95%;
    overflow-y: scroll;
}

:global(.true_show_files_scrollable_container .true_files_wrap_container) {
    display: flex;
    margin: 0 15px;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
}

:global(.true_show_files_scrollable_container::-webkit-scrollbar) {
    width: var(--t-scrollbar-width);

}

:global(.true_show_files_scrollable_container::-webkit-scrollbar-track) {
    background-color: transparent;
}

:global(.true_show_files_scrollable_container::-webkit-scrollbar-thumb) {
    background-color: var(--t-secondary-background);
    border-radius: 5px;
}

:global(.true_show_files_scrollable_container::-webkit-scrollbar-thumb:hover) {
    background-color: var(--t-secondary-background);
}