:global(.true_header_container.header_border) {
    border: 1px solid var(--t-border-color);
    margin-top: 8px;
    padding: 6px 15px 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--t-text-color);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    width: 100%;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: var(--t-primary);
    text-decoration: none;
}

h1 {
    font-size: 96px;
    font-weight: 300;
    line-height: 1.16;
}

h2 {
    font-size: 60px;
    font-weight: 300;
    line-height: 1.2;
}

h3 {
    font-size: 48px;
    line-height: 1.16;
}

h4 {
    font-size: 34px;
    line-height: 1.05;
}

h5 {
    font-size: 24px;
    line-height: 1;
}

h6 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
}