.column {
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.column_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mine_column {
  max-width: 254px;
}

.mine_column_content {
  background-color: var(--t-primary-50);
}

.other_column {
  background-color: var(--t-secondary-background-50);
}

.column_content {
  flex-grow: 1;
  padding: 10px;
  border-top: 2px solid var(--t-border-color);
}

.column_button_button {
  margin-bottom: 10px !important;
  width: 145px;
}

.column_button_icon {
  margin-right: 10px;
  margin-top: -3px;
}

.card {
  height: 75px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: var(--t-primary-background);
  border: 1px solid var(--t-border-color);
}

.card_with_custom_content {
  height: auto;
}

.selected_card {
  background-color: var(--t-primary-50);
}

.card:hover {
  cursor: pointer;
}

.assigned_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.team_icon {
  color: var(--t-secondary-background-50);
  font-size: var(--t-heading-5-size);
}

.user_icon {
  color: white;
  font-size: small !important;
  width: 30px !important;
  height: 30px !important;
  text-transform: uppercase;
}

.columns_container {
  display: flex;
  width: 100%;
  gap: 3px;
  flex-grow: 1;
  min-height: 100%;
}

.other_columns_container {
  display: flex;
  width: 100%;
  gap: 3px;
  flex-grow: 1;
}

.show_component {
  display: flex;
}

.hide_component {
  display: none;
}

@keyframes smooth-appear {
  to {
    bottom: 20px;
    opacity: 1;
  }
}

.show_card_content_container {
  width: 100%;
  bottom: -100%;
  opacity: 0;
  right: 50%;
  animation: smooth-appear 0.5s ease forwards;
  padding-top: 24px;
}

.card_content_component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card_component {
  flex-grow: 1;
  overflow: hidden;
}

.hide_card_component_section {
  height: 46px;
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--t-border-color);
}

.hide_section_text {
  flex-grow: 1;
}
