.custom_field_header_pair_text_container {
  display: flex;
  padding-right: 15px;
  align-items: center;
}

.custom_field_header_text_container {
  padding-right: 10px;
}

.custom_field_checkbox {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
