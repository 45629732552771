:global(.insured_related_insureds .collapse-content-accordion) {
    padding: 0 !important;
}

:global(.insured_related_insureds .collapse-content-accordion .true_table_row svg) {
    position: relative;
    top: 3px;
    left: 5px;
}

:global(.insured_related_insureds.hide_collapse_icon .MuiAccordionSummary-expandIconWrapper) {
    display: none;
}

:global(.insured_related_insureds.hide_collapse_icon .true_collapse_wrapper.compact_header .true_collapse.Mui-expanded>div:nth-child(2)) {
    border: none;
}

:global(#modalInsuredRelationships .MuiDialogContent-root) {
    overflow: hidden;
}

.modal_related_insured_disclaimer {
    font-size: 14px;
}

.search_insured_container {
    max-width: 75%;
}

.relationship_select_and_button_container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 15px;
}

.relationship_select__container {
    width: 40%;
}

.relationship_button_container {
    width: auto;
}