.premium_row_container {
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.premium_row_name_cell_3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_name_cell_5 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_name_cell_6 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: flex-start;
    width: 16%;
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_input_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_rate_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7%;
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_calculated_amount_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7%;
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_previous_running_total_cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 7%;
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_running_total_cell_10 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
    margin-right: calc(5% + 6px);
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.premium_row_running_total_cell_7 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 7%;
    margin-right: calc(5% + 6px);
    border-bottom: 1px solid var(--t-secondary-background-20);
}