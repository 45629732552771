:global(.true_modal_dialog_container.all_note_content .k-upload) {
    display: flex;
    background-color: transparent;
}

:global(.true_modal_dialog_container.all_note_content .k-upload .k-upload-files) {
    display: flex;
    border: none
}

:global(.true_modal_dialog_container.all_note_content .k-upload .k-upload-files li) {
    margin-right: 5px;
    border: none;
}

:global(.true_modal_dialog_container.all_note_content .k-upload .k-upload-files::-webkit-scrollbar) {
    width: var(--t-scrollbar-width);
    height: 5px;
}

:global(.true_modal_dialog_container.all_note_content .k-upload .k-upload-files::-webkit-scrollbar-track) {
    background: transparent;
}

:global(.true_modal_dialog_container.all_note_content .k-upload .k-upload-files::-webkit-scrollbar-thumb) {
    background-color: var(--t-secondary-background);
    border-radius: 5px;
}

:global(.true_modal_dialog_container.all_note_content .k-upload .k-upload-files::-webkit-scrollbar-thumb:hover) {
    background-color: var(--t-secondary-background);
}