.admin_tools_page_container {
    width: 100%;
}

.main_title_container {
    display: flex;
    justify-content: center;
}

.main_title {
    display: flex !important;
    width: 60%;
    border-bottom: 2px solid var(--t-border-color);;
}

.admin_tools_list_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    margin-top: 15px;
    width: 100%;
}

.tool_link{
    background-color: var(--t-secondary);
    color: var(--t-text-color) !important;
    text-decoration: none;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
}

.tool_content {
    display: flex;
    align-items: center;
}

.tool_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}