.true_base_grid_container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  flex-grow: 1;
  height: 100%;
}

/* /////////////////// SUB WRAPPER */
.true_base_grid_wrapper {
  background-color: transparent;
  align-self: auto;
  display: flex;
  height: 100%;
  min-height: 0;
  max-height: 100%;
}

/* ///////////////////////////// TOOLBAR */
.true_base_grid_toolbar {
  display: flex;
  align-self: auto;
  align-items: flex-start;
  padding-bottom: 0px;
  border-width: 1px 1px;
  border: 1px solid var(--t-border-color);
  margin-bottom: 5px;
  padding: 5px;
  flex-wrap: wrap;
}

:global(#true_base_grid_filter_reset) {
  cursor: pointer;
}

:global(#true_base_grid_filter_reset > .MuiChip-label) {
  padding-right: 0px;
}

:global(.true_base_grid_filter_sub_wrappers_item:not(:last-child)) {
  margin-right: 10px;
}

:global(
    .true_base_grid_filter_sub_wrappers_item .MuiLinearProgress-colorPrimary
  ) {
  background-color: lightgray !important;
}

:global(
    .true_base_grid_filter_sub_wrappers_item
      .true_select_options_container_table_filter
  ) {
  height: 35px;
}
.true_base_grid_toolbar > .reset_table_filters > span {
  font-size: var(--t-heading-5-size);
  color: var(--t-text-color);
}

.reset_table_filters > button {
  padding: "0px !important";
}

.true_base_grid_toolbar > .true_base_grid_search_filter {
  border-bottom: 1px solid var(--t-border-color);
  background-color: var(--t-secondary);
  border-radius: 10px 10px 0 0;
}

.true_base_grid_table_toolbar .true_base_grid_search_filter input::placeholder {
  opacity: 1;
  color: var(--t-text-color);
}

.true_base_grid_toolbar_container_actions {
  display: flex;
  flex-grow: 1;
}

.true_base_grid_search_filter {
  border-bottom: 1px solid var(--t-border-color);
  background-color: var(--t-secondary);
  border-radius: 10px 10px 0 0;
  padding: 0 10px;
  /* "input::placeholder": {
        opacity: 1,
        color: theme?.TEXT_COLOR,
      }, */
}

.true_base_grid_toolbar_buttons {
  margin-left: 10px;
  display: flex;
}

.true_base_grid_toolbar_filters > div:not(:last-child) {
  margin-right: 5px;
}

.true_base_grid_toolbar_exports > div:not(:last-child) {
  margin-right: 10px;
}

.true_base_grid_toolbar_buttons > div:not(:last-child) {
  margin-right: 10px;
}

.true_base_grid_toolbar_filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.true_base_grid_toolbar_filters
  .true_input_general_container
  .true_input_helper_text {
  margin-bottom: 0;
  margin-top: 0;
}

.true_base_grid_toolbar_filters .true_base_grid_search_filter {
  max-height: 32px;
}

.true_base_grid_toolbar_exports {
  display: flex;
  flex-wrap: wrap;
  justify-content: "space-between";
  /* padding: "2px"; */
}

/* /////////////////////// MIXED //////////////////////// BASETABLE TODO - needs additional clean-up */
.true_base_grid_body_row_bounding_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* height: 100%; */
}

.true_base_grid_body_row_bounding_container > .true_base_grid_row {
  min-height: 48px;
}

.true_base_grid_body .true_base_grid_body,
.true_base_grid_head > .true_base_grid_row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.true_base_grid_cell_header_initial_gap {
  width: 5px !important;
}

.true_base_grid_cell_header_final_gap {
  width: 35px !important;
}

/* /////////////////////// TABLE */
.true_base_grid {
  min-width: 350px; /* SM size on Material UI ??? 250px*/
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
}

.true_base_grid_head {
  background-color: var(--t-secondary-background);
  height: 35px;
  min-height: 35px;
  max-height: 35px;
}

/* /////////////////////// HEADER */
.true_base_grid_header_data {
  display: flex;
  align-items: center;
  border-left: 1px solid rgb(198, 198, 209);
  height: 35px;
  max-height: 35px;
  background: var(--t-secondary-background);
  color: var(--t-contrast-text-color);
  width: 100%;
}

.true_base_grid_header_data_column_name {
  color: var(--t-contrast-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.true_base_grid_header_data_column_name_order_arrow {
  height: 50%;
}

/* //////////////////////// MULTI - HEADER */
.true_base_grid_multi_cell_collapse {
  /* // width: "100% !important", */
  padding: 0px 0px 0px 1px !important;

  align-items: center;
  border-left: 1px solid #ffffff4d;
  background: #8e8ea4;
  font-weight: 400;
  font-size: 12px;
  color: #f6f6f9;
  width: 100%;
  border-top: none;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.true_base_grid_collapse_wrapper.multi_table .cell-collapse button {
  padding: "4px !important";
}

/* //////////////////////// HEADER RELATED */
.true_base_grid_head > .true_base_grid_table_row {
  margin-right: 5px;
}

.true_base_grid_head .true_base_grid_row .true_base_grid_header_data {
  /* // padding: "6px 1px 6px 7px !important", */
  padding: 0px 0px 0px 6px;
}

/* //////////////////////// BODY */
.true_base_grid_body {
  overflow: hidden;
}

.true_base_grid_row .true_base_grid_body_data:first-child {
  border-left: 1px solid var(--t-border-color);
}

.true_base_grid_row .true_base_grid_body_data:last-child {
  border-right: 1px solid var(--t-border-color);
}

:global(.true_base_grid_gap_filler) {
  padding: 0px 0px !important;
}

/* //////////////////////// BODY DATA */
.true_base_grid_body_data {
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: 0px 7px;
  border-bottom: 1px solid var(--t-border-color); /* ${getColorWithOpacityHexaDecimal(theme?.BORDER_COLOR,30)}; */
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--t-text-color);
  display: flex;
  align-items: center;
  user-select: none;
}

.true_base_grid_body_data div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* ////////////////////// SUB FOOTER */
.true_base_grid_sub_footer_component {
  display: flex;
  flex-grow: 1;
}

/* ////////////////////// FOOTER */
.true_base_grid_footer {
  background: var(--t-secondary-background);
}

.true_base_grid_row_select,
.true_base_grid_cell_select {
  border-radius: 4px;
  background-color: white !important;
  margin: -4px 0px -4px 0px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 6px 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 56px !important;
  z-index: 9999;
  background-blend-mode: normal !important;
}

.true_base_grid_row_select > .true_base_grid_body_data {
  border-bottom: none !important;
}

.true_base_grid_row_highlight:hover {
  background-color: rgba(211, 211, 211, 0.5);
  background-blend-mode: color-burn;
}

.true_base_grid_row {
  min-height: 49px;
  background-color: white;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.true_base_grid_footer .true_base_grid_row .true_base_grid_header_data {
  border-top: none;
}

/* ///////////////////// PHONE */
.container-phone-table-cell .true_input_general_container {
  min-width: 115px;
}

.container-phone-table-cell .true_phone_container {
  max-width: 115px;
}

.container-phone-table-cell .true_extension_container .true_input_container {
  display: flex !important;
  align-items: center;
  flex-wrap: nowrap;
}
.container-phone-table-cell
  .true_extension_container
  .true_input_container
  .phone-table-cell-extension {
  margin-left: 5px;
}

/* ////////////////////// LINK */
.true_base_grid_body_data .true_input_container .inputLinkCell {
  /* // TODO - update the name "inputLinkCell" as it's not up to par with naming standards */
  -webkit-text-fill-color: var(--t-primary) !important;
  cursor: pointer !important;
}

.true_base_grid_body_data > .inputLinkCell.read-only input {
  pointer-events: auto;
}

.linkCell-container {
  width: 100%;
}

/* ///////////////////// ERROR */
.errors-container {
  text-align: left;
  color: var(--t-danger);
  margin-left: 8px;
  font-size: small; /* theme?.SMALL_TITLE?.SIZE; */
  border-bottom: 1px solid var(--t-border-color);
}

.true_base_grid_compact_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  min-width: 120px;
  background-color: var(--t-secondary-background-50);
}

/* //////////////////// COLLAPSE */
.true_base_grid_body .true_collapse_wrapper {
  height: auto;
}

.true_base_grid_collapse_wrapper.multi_table .collapse_content_accordion {
  padding: 5px 0px 5px 5px;
}

.true_base_grid_collapse_wrapper.multi_table
  .true_base_grid_collapse_title_accordion {
  min-height: 40px;
  padding: 0px 6px;
}

.true_base_grid_collapse_wrapper.multi_table
  .true_base_grid_collapse_title_accordion
  > div {
  margin: 3px 0 !important;
}

.true_base_grid_no_result_found {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.true_base_grid_body::-webkit-scrollbar {
  width: var(--t-scrollbar-width);
}

.true_base_grid_body::-webkit-scrollbar-track {
  display: none;
}

.true_base_grid_body::-webkit-scrollbar-thumb {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

.true_base_grid_body::-webkit-scrollbar-thumb:hover {
  background-color: var(--t-secondary-background);
}

.true_base_grid_row_v3 {
  display: flex;
  flex-direction: "row";
  width: 100%;
  background-color: lightgray;
  height: 50px; /* Needs to be adjusted when the code in BaseGridRenderProcessor_v3. Look for "rowHeight" const. */
  align-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.true_base_grid_col_v3 {
  width: 9.09%;
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
}

.true_base_grid_render_wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

.true_base_grid_render_wrapper::-webkit-scrollbar-thumb {
  min-height: 50px;
}

.true_base_grid_render_container {
  position: relative;
  z-index: 1;
}

.true_base_grid_scroll_height {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.true_base_grid_end_row {
  padding: 15px;
  text-align: center;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #666;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
