.agency_information_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.top_section_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.bottom_section_container {
  display: flex;
  width: 100%;
  height: 45%;
  overflow: hidden;
}

.top_left_column_container {
  width: 45%;
  padding: 10px 10px 12px 10px;
}

.bottom_left_column_container {
  width: 66%;
  padding: 10px 10px 0px 10px;
}

.right_column_container {
  display: flex;
  flex-direction: column;
  width: 34%;
  padding: 10px 10px 0px 10px;
}

.top_right_table_container {
  height: 215px;
  padding-bottom: 10px;
}
