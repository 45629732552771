:global(div.true_stepper_container) {
    align-items: flex-start;
    margin: 12px;
}

:global(div.true_stepper_container .MuiTimelineConnector-root) {
    background-color: var(--t-primary-40);
}

:global(div.true_stepper_container .MuiTimelineContent-root) {
    color: var(--t-text-color);
}