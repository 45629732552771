.insured_landing_page_wrapper {
    display: flex;
    flex-direction: row !important;
    gap: 15px;
    height: auto;
    flex-wrap: wrap;
}

.insured_activity_container {
    min-height: 163px;
    height: 100%;
}

:global(div[class*="insured_first_row_second_column"]:empty) {
    display: none;
}

:global(div[class*="insured_activity_container"] .activity-container) {
    margin-top: 0;
    margin-bottom: 0;
}

:global(.landing-left-container .insured-landing-contacts-container .true_information_container) {
    margin-top: 0;
}

:global(.landing-right-container div.landing-container) {
    margin-top: 0;
}

:global(.landing-right-container div.landing-container .true_information_container) {
    margin-top: 0;
}

:global(div[class*="insured_first_row_second_column"] .true_input_general_container .true_input_container) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

:global(.contact-list .contact-item .right-column .true_phone_container .true_input_label) {
    height: 0;
}