.payment_bill_detail_icon_button {

    align-items: center;
}

:global(button[class*="payment_bill_detail_icon_button"] svg path) {
    transform: translate(4px, 4px);
}

.helper_for_service_code {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
}