.object {
  animation: MoveUpDown 8s linear infinite;
  position: relative;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 60px;
  }
}

.content {
  width: 100px;
  padding: 10px;
  color: #787878;
  background-color: #fcf7f8;
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
}