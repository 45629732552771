.true_row_grid_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.true_row_grid_wrapper .true_col_grid_wrapper .true_input_general_container,
.true_row_grid_wrapper .true_col_grid_wrapper .true_select_general_container,
.true_row_grid_wrapper .true_col_grid_wrapper .true_input_container {
  width: 100%;
}

.true_row_grid_wrapper.MuiGrid-root {
  flex-wrap: nowrap;
}

@media (max-width: 600px) {
  .true_row_grid_wrapper {
    flex-wrap: wrap !important;
  }
}