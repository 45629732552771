:global(.true_drawer_modal_container.MuiDrawer-modal) {
    z-index: 99;
}

:global(.true_drawer_modal_container .MuiDrawer-paper) {
    top: 200px;
    left: 10%;
    bottom: 1%;
    height: auto;
    background-color: var(--t-primary-background);
}

:global(.true_drawer_modal_container .MuiDrawer-paper::-webkit-scrollbar) {
    width: var(--t-scrollbar-width);
}

:global(.true_drawer_modal_container .MuiDrawer-paper::-webkit-scrollbar-track) {
    background-color: transparent;
}

:global(.true_drawer_modal_container .MuiDrawer-paper::-webkit-scrollbar-thumb) {
    border-radius: 5px;
    background-color: var(--t-secondary-background);
}

:global(.true_drawer_modal_container .MuiDrawer-paper::-webkit-scrollbar-thumb:hover) {
    background-color: var(--t-secondary-background);
}

:global(.true_drawer_modal_container .MuiDrawer-paper .true_drawer_content_container) {
    padding: 15px 15px 0px 15px;
}

:global(.true_drawer_modal_container .MuiDrawer-paper .true_drawer_content_container .true_drawer_content) {
    display: flex;
    align-items: baseline;
}

:global(.true_drawer_modal_container .MuiDrawer-paper .true_drawer_content_container .true_drawer_icon) {
    color: var(--t-text-color);
    font-size: var(--t-heading-5-size);
}

:global(.true_drawer_modal_container .MuiDrawer-paper .true_drawer_content_container .MuiPaper-root:not(.true_message)) {
    background-color: var(--t-primary-background);
}