:global(.true_information_container) {
  border-radius: 0;
}

:global(.true_information_container .true_title_container) {
  background: var(--t-secondary-background-50) !important;
  text-align: left;
  text-transform: capitalize;
  padding: 4px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

:global(.true_information_container .true_title_container a) {
  margin-left: 3px;
  color: var(--t-primary);
  text-decoration: none;
  cursor: pointer;
}

:global(.true_information_container > div:nth-of-type(2)) {
  border: 1px solid var(--t-border-color);
  border-top: none;
  margin-top: -1px;
}