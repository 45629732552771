.true_alert_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
}

.true_alert_information {
    background-color: var(--t-warning-50) !important;
}

.true_alert_warning {
    background-color: var(--t-danger-50) !important;
}

.true_alert_icon {
    background-color: transparent !important;
}

.true_alert_icon_span {
    color: var(--t-text-color);
    font-size: var(--t-heading-5-size);
}

.true_alert_message {
    background: transparent !important;
    color: var(--t-text-color);
    text-align: left;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin: 0 10px;
}

.true_alert_close_icon {
    cursor: pointer !important;
    background: transparent !important;
}

.true_alert_close_icon_span {
    color: var(--t-text-color) !important;
    font-size: var(--t-heading-6-size) !important;
}