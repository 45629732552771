:global(span.true_dot) {
    margin: 0;
    border-width: 2px;
    border-color: var(--t-border-color);
}

:global(span.true_dot.dot_active) {
    border-color: var(--t-primary);
}

:global(span.true_prev_dot) {
    margin: 0;
    border-width: 2px;
    color: var(--t-text-color);
    border-color: var(--t-primary);
    cursor: unset;
}

:global(span.true_prev.prev_dot_active) {
    cursor: pointer;
}

:global(span.disable_stepper_dot) {
    cursor: unset;
}