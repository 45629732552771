:global(div[class*="payment_schedule_table"]) {
  width: 100%;
}

:global(div[class*="payment_schedule_table"])
  div[class*="MuiBox-root"]
  div[class*="true_ui_base_table_container"]
  div[class*="true_ui_base_table_toolbar"] {
  border: none;
}

:global(div[class*="payment_schedule_table"])
  div[class*="MuiBox-root"]
  div[class*="true_ui_base_table_container"]
  div[class*="true_table_wrapper"]
  div[class*="true_ui_table"]
  div[class*="true_table_body"]
  div[class*="true_collapse_wrapper"]
  div[class*="true_collapse"]
  div[class*="true_collapse_title_accordion"] {
  background: var(--t-primary-background);
}

:global(span[class*="payment-schedule-table-title"]) {
  top: 45px;
  position: relative;
}

:global(span[class*="payment-schedule-table-title-compact"]) {
  top: 15px;
  position: relative;
}

:global(tr[class*="payment_schedule_multi_table_parent"]) {
  display: flex !important;
  width: 100%;
}

:global(tr[class*="payment_schedule_multi_table_parent"])
  > div[class*="true_table_body_data"] {
  border-bottom: none !important;
}

:global(div[class*="payment_schedule_multi_table_children"]) {
  border-top: 1px solid var(--t-secondary-background-30);
}
