
:global(div[class*="billing_batch_details_nacha_collpase_container"] .true_collapse_wrapper) {
    flex: 1;
    overflow: hidden;

}

:global(div[class*="billing_batch_details_nacha_collpase_container"] .true_collapse_wrapper .Mui-expanded:last-child) {
    height: 60%;
    display: flex;
    flex-direction: column;
}

:global(div[class*="billing_batch_details_nacha_collpase_container"] .true_collapse_wrapper .Mui-expanded:last-child >div) {
    width: 100%;
}

:global(div[class*="billing_batch_details_nacha_collpase_container"] .true_collapse_wrapper .Mui-expanded:last-child .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered) {
    height: 100%;
    flex: 1;
    overflow: hidden;
}