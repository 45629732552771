.state_container {
    padding: 5px;
}

.state_header_container {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    cursor: pointer;
    width: "fit-content";
}

.endorsement_form_row_information_container {
    display: flex;
    align-items: center;
    padding: 0 9px;
    justify-content: flex-start;
    width: 100%;
}

.switch_link_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    min-width: 150px;
}

:global(.MuiCheckbox-root) {
    padding: 0 9px !important;
}

:global(.optional_endorsement_form_row .MuiFormGroup-root) {
    width: 42px
}

