.exposure_table_container {
    border-bottom: 1px solid var(--t-secondary-background-20);
}

.exposure_table_column_header_container {
    display: flex;
    height: 35px;
    background-color: var(--t-secondary-background);
    color: var(--t-contrast-text-color);
    font-size: var(--t-body-size);
    font-weight: var(--t-bold-body-weight);
    align-items: center;
    margin-top: 5px;
}

.exposure_table_column_header {
    display: flex;
    align-items: center;
    height: 35px;
    min-width: auto;
    border-left: 1px solid var(--t-primary-background);
}

.exposure_table_width_30 {
    width: 30%;
}

.exposure_table_width_25 {
    width: 25%;
}

.exposure_table_width_20 {
    width: 20%;
}

.exposure_table_width_15 {
    width: 15%;
}

.exposure_table_width_10 {
    width: 10%;
}

.exposure_table_width_5 {
    width: 5%;
}

.exposure_table_column_header_text {
    width: 100%;
    text-align: center;
}

.exposure_table_row_container {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
}

.exposure_table_row_fields_container {
    display: flex;
}

.exposure_table_plus_minus_cell_container {
    display: flex;
    padding: 0 0 0 5px;
    justify-content: right;
    width: 5%;
}

.exposure_table_cell_container {
    padding: 0 5px;
}

.exposure_table_row_error {
 padding: 0 7px;
 color: var(--t-danger);
}