.documents_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 15px;
  overflow: hidden;
}

.documents_splitter {
  height: 100%;
  overflow: hidden;
}

.document_divider_section_container {
  padding: 10px;
}

.divider_section_title_container {
  height: 50px;
}

.single_document_container {
  display: flex;
  align-items: center;
}

.documents_commands_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--t-border-color-50);
  margin-top: 5px;
  padding: 3px 0;
}

.documents_commands_buttons_container {
  padding-right: 30px;
  min-width: 175px;
  margin-left: 10px;
}

:global(#document-packets-container-id .MuiCheckbox-root) {
  padding: 3px 9px;
}

:global(#documents-container-id .k-splitter) {
  border: 1px solid var(--t-border-color-50);
}
