.claim_correspondence_container {
    padding: 0 !important;
    height: 100%;
    overflow: hidden;
}

.claim_correspondence_email {
    margin-right: 10px;
}

.claim_correspondence_save_file {
    height: 31px;
}

.checkbox_documents_row_container {
    flex: 1;
    overflow-y: scroll;
    overflow-x: visible;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns >div) {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns >div:nth-child(2)) {
    flex: 1;
    overflow: hidden;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns >div >div) {
    height: 100%;
    overflow: hidden;
    flex: 1;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns >div:nth-child(2) >div >div) {
    flex-basis: auto;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns #claimCorrespondenceContainer >div) {
    flex: unset;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns #claimCorrespondenceContainer >div:last-child) {
    flex: 1;
    height: 100%;
    overflow: hidden;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns #claimCorrespondenceContainer >div:last-child >div) {
    flex: 1;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns div[class*="checkbox_documents_row_container"] .MuiFormControlLabel-label) {
    min-width: unset;
    max-width: 100%;
}

:global(div[class*="claim_correspondence_container"] .correspondence_columns div[class*="checkbox_documents_row_container"] .MuiFormControlLabel-label .true_switch_label) {
    overflow: hidden;
}
