.view_assignment_table_container {
    width: 100%;
}

:global(div[class*="view_assignment_table_container"] .true_table_body) {
    height: 100px !important;
    
}

:global(div[class*="view_assignment_table_container"]) {
    height: 225px !important;
    font-size: 14px;
}