.program_options_container {
  width: 100%;
  display: block;
  padding-right: 5px;
}

.form_label_program_options {
  color: var(--t-text-color) !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  display: block;
  text-align: left;
  padding-top: 10px !important;
}

:global(label[class*="switch_program_options"]) {
  flex-direction: row;
}

:global(label[class*="switch_program_options"] .MuiFormControlLabel-label) {
  width: 100px;
}

:global(div[class*="admin-program-policy-type"]) {
  padding-right: 7px;
}

:global(
    div[class*="admin-program-policy-type"]
      .true_input_general_container
      .true_input_container
      .true_input_label
  ) {
  min-width: 100px;
}

:global(
    div[class*="admin-program-payment-processor"]
      div[class*="select_true_input_label_container_for_select"]
  ) {
  min-width: 100px;
  margin-left: 0px;
}
