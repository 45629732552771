:global(div[class*="dashboard_bottom_content_container"] .true_ui_base_table_toolbar .toolbar_filters >div) {
    margin-right: 5px;
}

:global(div[class*="dashboard_bottom_content_container"] .true_ui_base_table_toolbar .toolbar_filters >div:last-child) {
    margin-left: auto;
}

:global(div[class*="dashboard_bottom_content_container"] .toolbar_container_actions .toolbar_filters) {
    width: 100%;
    justify-content: flex-start;
}

:global(div[id*="assigned_tasks_container"] .toolbar_container_actions .toolbar_filters .true_select_options_container_table_filter) {
    min-width: 120px;
}