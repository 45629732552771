.dashboard_bottom_content_container {

    padding: 0 20px;
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    flex: 1;
}

.dashboard_bottom_right_content {
    padding: 0 15px;
    padding-top: 1px;
    padding-bottom: 5px;
    height: 100%;
}

:global(div[class*="dashboard_bottom_content_container"] div[class*="taskBoard_card_content_component"]) {
    border-top: none;
}

:global(div[class*="dashboard_bottom_content_container"] div[class*="taskBoard_mine_column"] >div[class*="taskBoard_column_title"]) {
    padding-left: 10px;
}

:global(div[class*="dashboard_bottom_content_container"] div[class*="taskBoard_mine_column_content"]) {
    overflow-y: auto;
}

:global(div[class*="dashboard_bottom_content_container"] div[class*="taskBoard_mine_column"] >div[class*="taskBoard_column_title"] span) {
    font-weight: 500;
}