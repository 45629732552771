:global(.true_input_date_selector) {
  height: 46px;
  cursor: text;
  background-color: unset;
  justify-content: end;
}

:global(.true_input_date_selector .MuiInputLabel-formControl) {
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: var(--t-text-color);
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  display: inline;
  text-align: start;
  max-width: none;
  overflow: visible;
  text-overflow: ellipsis;
  font-family: Roboto;
  transform: unset;
  position: relative;
}

:global(.true_input_date_selector .MuiInputBase-formControl) {
  height: 31px;
  background-color: var(--t-secondary);
  -webkit-transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

:global(
    .true_input_date_selector .MuiInputBase-formControl .MuiInputBase-input
  ) {
  padding: 4px 12px;
}
