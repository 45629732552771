.color_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.preferences_colors_container {
  display: flex;
  align-self: center;
}

.i_container {
  width: 100%;
  border: 2px solid var(--t-border-color);
  padding: 3px;
  border-radius: 2px;
  color: var(--t-text-color);
  background-color: var(--t-primary-background);
}

:global(.user_form_view) {
  height: 100%;
  display: flex;
  flex-direction: column;
}
:global(.user_form_view::-webkit-scrollbar) {
  width: var(--t-scrollbar-width);
}

:global(.user_form_view::-webkit-scrollbar-track) {
  background-color: transparent;
}

:global(.user_form_view::-webkit-scrollbar-thumb) {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

:global(.user_form_view::-webkit-scrollbar-thumb:hover) {
  background-color: var(--t-secondary-background);
}

:global(.access_rights_and_permissions) {
  display: flex;
  align-items: stretch;
  gap: 20px;
  flex: 1;
  overflow: hidden;
}

:global(.admin_permissions_container) {
  width: 60%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
:global(.admin_permissions_title) {
  padding-left: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

:global(.admin_permissions_section) {
  border: 1px solid var(--t-border-color);
  padding: 10px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

:global(.admin_permissions_actions) {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:global(.admin_permissions_checks) {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
}

:global(.admin_permissions_checks .true_switch_container .MuiCheckbox-root) {
  padding: 5px;
}

:global(.admin_permissions_checks::-webkit-scrollbar) {
  width: var(--t-scrollbar-width);
}

:global(.admin_permissions_checks::-webkit-scrollbar-track) {
  background-color: transparent;
}

:global(.admin_permissions_checks::-webkit-scrollbar-thumb) {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

:global(.admin_permissions_checks::-webkit-scrollbar-thumb:hover) {
  background-color: var(--t-secondary-background);
}

:global(.admin_permissions_actions) {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

:global(.command_section_container) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
