/* Underwriter Notes*/
.underwriter_notes_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button_container {
  display: flex;
  justify-content: flex-end;
}

.style_box_container {
  height: 100%;
  margin-top: 10px;
}

.style_box_border {
  border-right: 2px solid !important;
}

/* Note Content */
.underwriter_note_content_expanded {
  text-align: justify;
  white-space: pre-wrap;
  margin-left: 15px !important;
  margin-right: 30px !important;
  margin-bottom: 30px !important;
  word-break: break-all;
}

.underwriter_note_content_collapsed {
  display: -webkit-box;
  text-align: justify;
  margin-left: 15px !important;
  margin-right: 30px !important;
  margin-bottom: 30px !important;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 10; /* total lines to show */
  -webkit-line-clamp: 10; /* total lines to show */
  word-break: break-all;
}

.underwriter_note_content_collapsed p {
  color: var(--t-text-color) !important;
}

.underwriter_note_content_collapsed p span {
  color: var(--t-text-color) !important;
}

/* Note Header */
.underwriter_note_edit_button {
  margin-right: 15px !important;
  margin-left: 20px !important;
}
