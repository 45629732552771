.user_tabs_container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    margin: 0 15px;
}

.user_preferences_title_container {
    padding-bottom: 20px;
}

.buttons_container {
    margin-top: 20px;
}

.buttons_container button {
    margin-left: 5px;
}

.preferences_colors_container {
    display: flex;
    align-self: flex-end;
}

.accept_sms_container {
    align-self: flex-end;
}

.user_preferences_form_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 50%;
}

.i_container {
    width: 100%;
    border: 2px solid var(--t-border-color);
    padding: 6px;
    border-radius: 2px;
    color: var(--t-text-color);
    background-color: var(--t-primary-background);
}