.container_form_class_code_rates {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.container_form {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.expired_switch {
  align-items: flex-start !important;
}

.help_icon {
  color: var(--t-primary);
}

.tooltip_title {
  white-space: pre-line;
}

.tooltip_line {
  display: block;
}
