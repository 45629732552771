/* ---------- SELECT TAGS ---------- */
.select_tag {
    border-radius: 20px;
    width: fit-content;
    display: flex;
    column-gap: 4px;

}

.green_tags {
    background-color: var(--t-success);
    color: var(--t-contrast-text-color);
    padding: 2px 6px 0 6px;
}

.green_tags * {
    color: var(--t-contrast-text-color) !important;
}

.gray_tags {
    background-color: var(--t-secondary-background-20);
    color: var(--t-text-color);
    padding: 6px 8px;
}

.gray_tags * {
    color: var(--t-text-color) !important;
}

.select_tag p {
    padding: 0;
    margin: 0;
    width: fit-content;
    align-self: center;
    cursor: default;
    font-size: var(--t-small-title-size);
}

.select_tag>button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.select_tag>button * {
    font-size: 10px !important;
}

.multiSelect_tags_container {
    display: flex;
    column-gap: 4px;
    row-gap: 2px;
    flex-wrap: wrap;
}

/* ---------- MULTISELECT COMPONENT ---------- */
.true_input_general_container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.true_input_container_start {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.true_input_container_top {
    display: flex;
    flex-direction: column;
}

.true_input_container_end {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    column-gap: 5px;
}

.true_input_container_bottom {
    display: flex;
    flex-direction: column-reverse;
}

.true_input_select_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5em;
    cursor: pointer;
    border-bottom: 1px solid var(--t-border-color);
    padding-left: 5px;
    background: transparent;
    border-radius: 4px 4px 0 0;
    min-height: 32px;
    height: fit-content !important;
}

.true_input_select_container:focus {
    border-bottom: 2px solid var(--t-primary);
}

.true_input_select_container:focus-visible {
    outline: none;
}

.true_select_option_container_paper {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
}

.select_displayed_option {
    color: var(--t-text-color);
    white-space: nowrap;
    overflow: hidden !important;
}

.filled {
    background: var(--t-secondary);
    height: 31px;
    padding-left: 12px;
}

.true_input_select_container_read_only {
    background-color: transparent;
    border: none;
    cursor: default;
    line-height: 1.5;
    padding: 7px 15px 7px 12px;
}

.true_input_select_container_read_only:focus {
    border: none !important;
}

.true_input_select_container_is_disabled {
    background-color: transparent;
    border-bottom: 1px solid var(--t-border-color);
    cursor: default;
}

.true_input_select_container_is_disabled:focus {
    border-bottom: 1px solid var(--t-border-color) !important;
}

.select_displayed_option_is_disabled {
    color: var(--t-input-text-color);
}

.true_input_select_container_has_error {
    border-color: var(--t-danger);
}

.true_input_error_txt {
    color: var(--t-danger);
    font-size: var(--t-small-title-size);
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.select_arrow_container {
    margin-top: 2px;
    color: var(--t-text-color);
}

.select_arrow_icon_open {
    transform: rotate(180deg);
}

.true_input_select_container_open {
    border-color: var(--t-primary);
}

.true_select_options_container {
    position: absolute;
    margin: 5px 0px 5px 0px;
    padding: 5px 0px 5px 0px;
    z-index: 9999;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    overflow-y: auto;
    background-color: var(--t-primary-background);
}

.true_select_option {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    padding: 4px 8px 4px 14px;
    height: auto;
    border-radius: 0;
    margin: 0;
    background-color: var(--t-primary-background);
    overflow-x: clip;
}

.true_select_option:focus,
.true_select_option:focus-visible,
.true_select_option_is_selected:hover .true_select_option_is_selected:focus,
.true_select_option_is_selected:focus-visible {
    background-color: var(--t-primary-10);
    outline: none;
}

.true_select_option:hover {
    background: var(--t-secondary-background-10);
}

.empty_option>span {
    height: 18px;
}

.true_select_option>span {
    overflow: hidden;
}

.true_select_option_is_selected {
    background-color: var(--t-primary-50);
}

.true_select_option_empty_option {
    height: 29;
}

.true_input_label_container_for_select {
    display: flex;
    margin-left: 2px;
    justify-content: space-between;
}

.label_for_select {
    display: block;
    width: 90%;
}

.true_input_select_container::-webkit-scrollbar {
    width: var(--t-scrollbar-width);
}

.true_input_select_container::-webkit-scrollbar-track {
    background: transparent;
}

.true_input_select_container::-webkit-scrollbar-thumb {
    background: var(--t-secondary-background);
    border-radius: 5px;
}

.true_input_select_container::-webkit-scrollbar-thumb:hover {
    background: var(--t-secondary-background);
}

/*////*/

.true_select_options_container::-webkit-scrollbar {
    width: var(--t-scrollbar-width);
}

.true_select_options_container::-webkit-scrollbar-track {
    background: transparent;
}

.true_select_options_container::-webkit-scrollbar-thumb {
    background: var(--t-secondary-background);
    border-radius: 5px;
}

.true_select_options_container::-webkit-scrollbar-thumb:hover {
    background: var(--t-secondary-background);
}

/* TABLE */

:global(.true_select_options_container_table_filter) {
    min-width: 150px;
    right: 0;
    left: unset;
}

:global(.true_select_options_container_table_cell) {
    min-width: 80px;
    padding: 0 !important;
}

.true_select_options_container>button {
    background: #ffffff;
    border: none;
}

.clear_icon_container {
    padding-left: 5px;
}