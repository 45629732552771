:global(.hierarchy_list_container) {
  width: 100%;
}

:global(.checkbox_item_list .MuiCheckbox-root) {
  padding: 6px;
  margin-left: -6px;
}

:global(.parent_item_container_collapse) {
  cursor: pointer;
}

:global(.item_title) {
  display: flex;
  align-items: center;
}

:global(.parent_item_content) {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-left: 40px;
}

:global(.parent_item_content.open) {
  max-height: fit-content;
  transition: max-height 0.2s ease-in;
}
