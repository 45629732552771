.payment_history_content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title_content {
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.payment_status_history_table_wrapper {
  overflow-y: auto;
  height: 250px;
  margin-bottom: 10px;
}

.payment_status_history_table_wrapper::-webkit-scrollbar {
  width: var(--t-scrollbar-width);
}

.payment_status_history_table_wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.payment_status_history_table_wrapper::-webkit-scrollbar-thumb {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

.payment_status_history_table_wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--t-secondary-background);
}

:global(#PaymentStatusHistoryTable) {
  height: auto;
}

.actions_content {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-bottom: 15px;
  margin-top: auto;
}
