.account_and_balance_table_wrapper {
    height: 100%;
}

:global(#account-and-balance-table-wrapper #AccountAndBalanceTable) {
    height: 100%;
    overflow-y: auto
}

:global(#account-and-balance-table-wrapper #AccountAndBalanceTable >*) {
    height: 100%;
}

.account_and_balance_table_section {
    margin-top: 20px;
    display: flex;
    height: 100%;
    overflow: hidden;
    height: 228px;
    min-height: 228px;
    width: 100%;
}