.example .true_col_grid_wrapper {
    background-color: lightgray;
    margin: 1px;
}

.item-for-row-wrapping {
    display: flex;
    flex-direction: column;
    align-items: center;
}

u {
    font-weight: bolder;
    color: #1785D8;
}