.underwriting_container {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.underwriting_task_board_container {
  width: 100%;
  height: 100%;
  overflow: auto;
}
