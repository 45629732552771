.container_tabs {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: hidden;
}

:global(div[class*="container_tabs"] ul) {
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-behavior: smooth;

}

:global(div[class*="container_tabs"] ul::-webkit-scrollbar) {
    display: none;
}

.controls {
    display: flex;
    width: 100%;
    position: absolute;
    z-index: 6;
    height: auto;
    margin: 15px 1px;
}

.tabs_button {
    height: 30px !important;
    width: 30px !important;
    cursor: pointer !important;
    padding: 0 !important;
    min-width: 30px !important;
}

.left_button {
    position: absolute !important;
    left: 0 !important;
}

.right_button {
    position: fixed !important;
    right: 0 !important;
}

.insured_instance_wrapper_tabs {
    height: 100%;
}

.insured_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
}

.right_insured_wrapper {
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

.insured_body_wrapper {
    width: 100%;
    margin: 0px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.insured_body_components {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    height: 100%;
    overflow: scroll;
}

:global(div[class*="insured_body_components"] >div) {
    height: 100%;
    display: flex;
    flex-direction: column;
}

:global(div[class*="insured_body_components"] >div >div:last-child) {
    flex-grow: 1;
}