:global(div.true_stepper_avatar) {
    width: 30px;
    height: 30px;
    font-size: 14px;
    background-color: var(--t-primary-background);
    outline-offset: unset;
    outline-width: unset;
    outline-color: unset;
    outline-style: unset;
    color: var(--t-text-color);
}

:global(div.true_stepper_avatar.avatar_active) {
    background-color: var(--t-primary);
    outline-offset: -3px;
    outline-width: 5px;
    outline-color: var(--t-primary);
    outline-style: solid;
    color: var(--t-primary-background);
}