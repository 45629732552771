/* Audit */

.audit_container {
  overflow: hidden;
}

.audit_body_selector_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.audit_scrollable_body_container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.audit_information_container {
  display: flex;
}

.audit_information_left_container {
  width: 60%;
}

.audit_information_right_container {
  width: 40%;
  min-height: 163px;
  height: 95%;
  margin: 0px 16px 16px 16px;
}

/* Audit Results */

.informative_message_audit_results {
  padding-right: 150px;
}

.audit_results_and_docs_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
