:global(div[class*="widget-calendar"]) {
  max-width: 220px;
  border: none !important;
}

:global(div[class*="widget-calendar"] .k-calendar-header) {
  min-width: 220px;
}

:global(div[class*="widget-calendar"] .k-calendar-header .k-spacer) {
  display: none;
}

:global(div[class*="widget-calendar"] .k-calendar-header .k-button) {
  gap: 0;
  margin-right: 0;
  font-size: 12px;
  width: 50%;
}

:global(div[class*="widget-calendar"] .k-calendar-header .k-calendar-nav) {
  gap: 0;
  width: 50%;
}

:global(div[class*="widget-calendar"] .k-calendar-monthview) {
  margin-bottom: 5px;
  max-width: 220px;
}

:global(
    div[class*="widget-calendar"]
      .k-calendar-monthview
      .k-calendar-table
      .k-calendar-thead
      .k-calendar-tr
      .k-calendar-th
  ) {
  font-size: 10px;
}

:global(td[class*="widget-calendar-cell"]) {
  border: 1px solid var(--t-border-color);
  background-color: var(--t-primary-50);
}

:global(td[class*="widget-calendar-cell"] .k-link) {
  color: var(--t-text-color);
  width: 52px;
  height: 52px;
}

:global(
    div[class*="widget-calendar"]
      .k-calendar-monthview
      .widget-calendar-cell
      .k-link
  ) {
  justify-content: flex-end;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 2px;
  padding-right: 2px;
  font-size: 9px;
  width: 28px;
  height: 28px;
}

:global(td[class*="widget-calendar-cell"].k-weekend) {
  background-color: var(--t-contrast-background-10);
}

:global(td[class*="widget-calendar-cell"].k-selected) {
  background-color: var(--t-contrast-background);
}

:global(td[class*="widget-calendar-cell"].k-selected .k-link) {
  color: var(--t-contrast-text-color);
}

:global(td[class*="widget-calendar-cell"].k-today .k-link) {
  color: var(--t-danger);
}

:global(
    div[class*="widget-calendar"]
      .widget-calendar-cell
      .k-link
      .widget-calendar-cell-data
  ) {
  display: none;
}

:global(div[class*="widget-calendar"] .k-calendar-view .k-calendar-table) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

:global(
    div[class*="widget-calendar"]
      .k-calendar-monthview
      .widget-calendar-cell
      .k-link
      .widget-calendar-cell-data
  ) {
  display: inline;
  margin-top: 14px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2px;
  position: absolute;
  width: -webkit-fill-available;
}

:global(
    td[class*="widget-calendar-cell"].k-selected
      .k-link
      .widget-calendar-cell-data
  ) {
  color: var(--t-contrast-text-color);
}
