/* Endorsement */

.endorsement_container {
  overflow: hidden;
}

.endorsement_body_selector_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.endorsement_scrollable_body_container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* Endorsement Header */

.endorsement_header_container {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  width: 100%;
}

.endorsement_header_pair_text_container {
  display: flex;
  padding-right: 15px;
  align-items: center;
}

.endorsement_header_text_container {
  padding-right: 10px;
  float: left;
  width: fit-content;
}

/* Endorsement Bottom Buttons */

.endorsement_bottom_buttons_container {
  display: flex;
  width: 100%;
  height: 70px;
}

.endorsement_bottom_buttons_left_column {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.endorsement_bottom_buttons_right_column {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.endorsement_bottom_buttons_right_margin {
  margin-right: 15px;
}

/* Endorsement Information */

.endorsement_types_container {
  display: flex;
  width: 80%;
}

.endorsement_type_column_container {
  width: 50%;
  :global(.MuiButtonBase-root) {
    padding: 5px 15px;
  }
}

/* Exposure and Premium  */

.exposure_and_premium_footer_container {
  display: flex;
  width: 100%;
  background-color: var(--t-secondary-background-50);
  padding-left: 16px;
  padding-right: calc(8px + 5%);
  justify-content: space-between;
}

.exposure_table_expanded_information_container {
  width: 100%;
}

.exposure_table_header_container_collapsed {
  display: flex;
  height: 31px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.exposure_table_header_container_expanded {
  display: flex;
  height: 31px;
  width: 100%;
  align-items: center;
}

.footer_header_description_container {
  display: flex;
  align-items: center;
  width: 20%;
}

.exposure_header_description_expanded {
  display: flex;
  align-items: center;
  width: 250px;
}

.footer_header_calculation_label_container {
  display: flex;
  align-items: center;
}

.footer_header_margin_left {
  margin-left: 20px;
}

.footer_header_calculation_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

:global(.collapsable_state_container) {
  width: 100%;
}

:global(.collapsable_state_container .true_collapse_title_accordion) {
  min-height: fit-content !important;
  margin: 0 !important;
}

:global(.collapsable_state_container .collapse_content_accordion) {
  padding: 0px 5px 5px 5px !important;
}

:global(.collapsable_state_container .MuiAccordionSummary-contentGutters) {
  margin: 0 !important;
}

:global(.true_collapse_wrapper svg) {
  color: inherit;
}

.header_delete_state_container {
  display: flex;
  justify-content: flex-end;
  width: calc(5% - 30px);
}

/* Exposure table */

.exposure_table_vertical_padding_5 {
  padding: 5px 0;
}

/* Bind Instructions */

.endorsement_bind_instructions_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 5px 5px 0 5px;
}

.endorsement_bind_instructions_body_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.endorsement_bind_button_container {
  position: fixed;
  bottom: 75px;
  right: 60px;
}

.endorsement_bind_button {
  height: 52px !important;
  padding: 16px !important;
  margin-bottom: 10px;
  border-radius: 50px !important;
  width: fit-content;
  background: var(--t-secondary-text-color) !important;
}

.endorsement_bind_button:hover {
  background: var(--t-secondary-text-color);
}

.endorsement_informative_reporting_bind_instructions_text {
  padding-top: 15px;
  padding-bottom: 5px;
}

.endorsement_reporting_bind_instructions_table {
  width: 40%;
}

.endorsement_schedule_bind_instructions_table {
  width: 85%;
}

.endorsement_bind_instructions_table_label {
  padding: 15px 0 0 0;
}

.endorsement_bind_instructions_schedule_table_inputs {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.endorsement_schedule_multi_table_footer {
  padding-right: 30px !important;
}

:global(.MuiAccordionDetails-root) {
  padding: 0 0 0 6px !important;
}

:global(div[class*="endorsement_schedule_bind_instructions_table"])
  div[class*="MuiBox-root"]
  div[class*="true_ui_base_table_container"]
  div[class*="true_ui_base_table_toolbar"] {
  border: none;
}

/* Policy Docs */
:global(.endorsement_documents_main_container) {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

:global(.endorsement_splitter_body_container) {
  height: 100%;
}

:global(.endorsement_splitter_body_container .k-splitter) {
  height: 90%;
  background-color: transparent;
  border-color: var(--t-border-color-20);
}

:global(.endorsement_splitter_body_container .true_style_box) {
  margin-top: 10px;
}

:global(.endorsement_splitter_body_container .true_style_box button) {
  margin-left: 16px;
}

:global(.endorsement_splitter_body_container .k-splitter .k-splitbar) {
  background-color: var(--t-border-color-20);
}

:global(.endorsement_splitter_body_container .k-splitter .k-splitbar:focus) {
  background: var(--t-primary);
}

:global(.endorsement-policy-documents-title) {
  margin-bottom: 10px !important;
}

:global(.endorsement-documents-states-row) {
  display: contents;
}

:global(.endorsement-policy-documents-row) {
  display: contents;
}

:global(
    .endorsement-policy-documents-row
      .true_col_grid_wrapper
      .MuiTypography-subtitle2
  ) {
  cursor: pointer;
  text-decoration-color: var(--t-text-color);
  text-decoration-thickness: 2px;
}

:global(
    .endorsement-documents-forms-row
      .true_row_grid_wrapper
      .true_col_grid_wrapper
  ) {
  min-width: fit-content;
}

:global(
    .endorsement-documents-forms-row
      .true_row_grid_wrapper
      .true_col_grid_wrapper
      .MuiCheckbox-root
  ) {
  padding: 0px 9px;
}

:global(.title-required-form) {
  color: var(--t-primary) !important;
}
