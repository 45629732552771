:global(div.name-plus-minus-cell-container) {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

:global(div.name-plus-minus-cell-container .plus-minus-icon-container) {
  padding: 0;
  margin: 0px;
  color: var(--t-primary);
}

:global(div.name-plus-minus-cell-container .plus-minus-icon-container:hover) {
  background-color: var(--t-primary-30);
}

:global(
    div.name-plus-minus-cell-container
      .plus-minus-icon-container
      .plus-action-button
  ) {
  color: var(--t-text-color);
}

:global(
    div.name-plus-minus-cell-container
      .plus-minus-icon-container
      .minus-action-button
  ) {
  color: var(--t-text-color);
}
