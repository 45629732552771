:global(.true_conversation_wrapper) {
  overflow: auto;
  height: calc(100vh - 420px);
}

:global(.true_conversation_wrapper.expanded) {
  height: calc(100vh - 740px);
}

:global(.true_conversation_wrapper::-webkit-scrollbar) {
  width: var(--t-scrollbar-width);
}

:global(.true_conversation_wrapper::-webkit-scrollbar-track) {
  background-color: transparent;
}

:global(.true_conversation_wrapper::-webkit-scrollbar-thumb) {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

:global(.true_conversation_wrapper::-webkit-scrollbar-thumb:hover) {
  background-color: var(--t-secondary-background);
}

@media (max-width: 1600px) {
  :global(.true_conversation_wrapper) {
    height: calc(100vh - 410px);
  }

  :global(.true_conversation_wrapper.expanded) {
    height: calc(100vh - 725px);
  }
}

@media (max-width: 1440px) {
  :global(.true_conversation_wrapper) {
    height: calc(100vh - 415px);
  }

  :global(.true_conversation_wrapper.expanded) {
    height: calc(100vh - 765px);
  }
}

:global(.true_conversation_wrapper .true_note_parent) {
  width: 100%;
  margin-top: 10px;
}

:global(.true_conversation_wrapper .true_note_parent .true_message) {
  margin: 8px 16px;
  font-size: var(--t-body-size);
  padding: 8px;
  width: 200px;
}

:global(
    .true_conversation_wrapper
      .true_note_parent
      .true_message
      .true_message_content
  ) {
  overflow: hidden;
  white-space: pre-wrap;
}

:global(
    .true_conversation_wrapper
      .true_note_parent
      .true_message
      .true_message_content
      > p
  ) {
  min-height: 20px;
}

:global(.true_conversation_wrapper .true_note_parent .true_message_parent) {
  flex-grow: 1;
  border-radius: 10px 10px 10px 0 !important;
  margin-left: 15px;
  width: 200px;
  max-width: 90%;
}

:global(.true_conversation_wrapper .true_note_parent .true_message_child) {
  border-radius: 10px 10px 0 10px !important;
  margin-right: 15px;
  background-color: var(--t-primary);
  color: var(--t-text-color) !important;
  width: auto;
  max-width: 85%;
}

:global(
    .true_conversation_wrapper .true_note_parent .true_note_information_child
  ) {
  margin-left: 15px;
  color: var(--t-text-color);
}

:global(
    .true_conversation_wrapper .true_note_parent .true_note_information_parent
  ) {
  margin-right: 15px;
  color: var(--t-text-color);
}

:global(.true_conversation_wrapper .true_note_parent .true_note_icon) {
  display: flex;
  justify-content: flex-end;
}

:global(
    .true_conversation_wrapper .true_note_parent .true_note_icon .attach-icon
  ) {
  transform: rotate(145deg);
}

:global(
    .true_conversation_wrapper
      .true_note_parent
      .true_note_icon
      .alert-icon-warning
  ) {
  color: var(--t-danger);
}

:global(
    .true_conversation_wrapper
      .true_note_parent
      .true_note_icon
      .true_alert_icon_information
  ) {
  color: var(--t-warning);
}

:global(.true_conversation_wrapper .true_note_parent .true_note_icon svg) {
  font-size: var(--t-title-size);
}

:global(
    .true_conversation_wrapper
      .true_note_parent
      .true_note_icon
      svg:hover:not(.true_attach_icon):not(.true_alert_icon)
  ) {
  cursor: pointer;
}

:global(
    .true_conversation_wrapper .true_note_parent .true_message_description
  ) {
  display: flex;
  margin-left: 16px;
  margin-bottom: -5px;
  color: var(--t-text-color);
  font-size: var(--t-caption-size);
}

:global(.true_conversation_wrapper .true_empty_message_container) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

:global(.true_conversation_wrapper .true_empty_message_container) {
  color: var(--t-secondary-background);
  font-style: italic;
}
