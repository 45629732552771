:global(.true_rich_editor_container) {
    width: 100%;
}

:global(.true_rich_editor_container .true_rich_editor) {
    width: 100%;
}

:global(.true_rich_editor_container .read_only_disabled .k-editor-toolbar) {
    pointer-events: none;
    opacity: 0.7;

}

:global(.true_rich_editor_container .true_rich_editor .read_only_disabled .k-editor-content > div) {
    pointer-events: none;
    opacity: 0.7;
    width: auto;
    height: auto;
    overflow: unset;
}

:global(.true_rich_editor_container .true_rich_editor .k-editor-content::-webkit-scrollbar) {
    width: var(--t-scrollbar-width);
    height: 5px;
}

:global(.true_rich_editor_container .true_rich_editor .k-editor-content::-webkit-scrollbar-track) {
    background: transparent;
}

:global(.true_rich_editor_container .true_rich_editor .k-editor-content::-webkit-scrollbar-thumb) {
    background: var(--t-secondary-background);
    border-radius: 5px;
}

:global(.true_rich_editor_container .true_rich_editor .k-editor-content::-webkit-scrollbar-thumb:hover) {
    background: var(--t-secondary-background);
}

:global(.true_rich_editor_container .true_rich_editor .k-editor-content > div) {
    text-align: left;
}

:global(.true_rich_editor_container .true_rich_editor .k-editor-content > div ul li p) {
    margin: 0;
}

:global(.true_rich_editor_container .true_rich_editor .true_input_error_txt) {
    color: var(--t-danger);
    font-size: var(--t-small-title-size);
    text-align: left;
}

:global(.true_rich_editor_container .true_rich_editor.true_rich_editor_class) {
    background-color: var(--t-primary-background);
    border: 1px solid var(--t-border-color-30);
    color: var(--t-text-color);
}

.true_rich_editor .k-editor-content>div {
    text-align: left;
}

.true_rich_editor .k-editor-content>div ul li p {
    margin: 0;
}

.k-animation-container {
    z-index: 10000 !important;
}