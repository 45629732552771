.ticker_container { 
    display: flex; 
    height: 31px; 
}

.ticker_inner_container {
    height: 100%;
    display: flex;
    align-items: center;
}

.ticker_value_container {
    margin-right: 5px;
}

.ticker_indicator_container_margin_right {
    margin-right: 7px;
}
