.left_insured_wrapper {
  width: 190px; 
  min-width: 190px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sub_side_nav_wrapper {
  flex: 1;
}

/* assignments view */

.assignments_view_wrapper {
  height: 235px;
  border-color: var(--t-secondary-background-50);
  border-style: solid;
  border-width: 0 5px 5px 5px;
  padding: 5px 0 5px 20px;
}

.group_list_wrapper {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: clip;
}

.assignees_List_wrapper {
  margin-top: 8px;
}

.assignee_wrapper {
  display: flex;
  column-gap: 15px;
  margin-right: 2px;
  width: 95%;
  overflow: hidden;
}
