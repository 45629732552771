:global(.true_collapse_wrapper) {
    margin-bottom: 0px;
    margin-top: 1px !important;
    text-align: left;
    width: 100%;
}

:global(.true_collapse_wrapper.margin_bottom) {
    margin-bottom: 8px;
}

:global(.true_collapse_wrapper.shadow_container) {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

:global(.true_collapse_wrapper > svg) {
    color: var(--t-text-color);
}

:global(.true_collapse_wrapper.height_auto .MuiButtonBase-root.Mui-expanded) {
    min-height: auto;
    padding: 10px 16px;
}

:global(.true_collapse_wrapper .MuiButtonBase-root.Mui-expanded) {
    min-height: 64px;
    padding: 0 16px;
}

:global(.true_collapse_wrapper.height_auto .MuiAccordionSummary-content.Mui-expanded) {
    margin: 0px;
}

:global(.true_collapse_wrapper .MuiAccordionSummary-content.Mui-expanded) {
    margin: 20px 0px;
}

:global(.true_collapse_wrapper .MuiAccordionSummary-expandIconWrapper) {
    transform-origin: center center;
}

:global(.true_collapse_wrapper .MuiAccordionSummary-expandIconWrapper.Mui-expanded) {
    transform: rotate(90deg);
}

/*accordion styles*/

:global(.true_collapse_wrapper .MuiCollapse-root) {
    visibility: inherit;
}

:global(.true_collapse_wrapper.has_read_only_content .MuiAccordionDetails-root) {
    padding: 8px 11px 5px;
}

:global(.true_collapse_wrapper.has_read_only_content .MuiCollapse-root) {
    visibility: visible;
    min-height: fit-content !important;
    height: fit-content !important;
}

:global(.true_collapse_wrapper .MuiAccordion-root) {
    border: 1px solid var(--t-border-color-30);
}

:global(.true_collapse_wrapper .MuiAccordion-root.Mui-expanded) {
    height: auto;
}

:global(.true_collapse_wrapper.compact_header .true_collapse .true_collapse_title_accordion) {
    max-height: 28px;
    min-height: unset;
    background-color: var(--t-secondary-background-50);
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

:global(.true_collapse_wrapper.compact_header .true_collapse .true_collapse_title_accordion >div:nth-child(1)) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

:global(.true_collapse_wrapper.compact_header .true_collapse .true_collapse_title_accordion button) {
    padding: 0;
}

:global(.true_collapse_wrapper.compact_header) {

    margin-bottom: 0;
}

:global(.true_collapse_wrapper.compact_header .true_collapse) {
    border: none;
}

:global(.true_collapse_wrapper.compact_header .true_collapse>div:nth-child(2)) {
    transition: none;
}

:global(.true_collapse_wrapper.compact_header .true_collapse.Mui-expanded>div:nth-child(2)) {
    max-height: 256px;
    overflow-y: auto;
    border: 1px solid var(--t-border-color);
    border-top: none;
}

:global(.true_collapse_wrapper.compact_header .true_collapse.Mui-expanded>div:nth-child(2)::-webkit-scrollbar) {
    width: var(--t-scrollbar-width);
}

:global(.true_collapse_wrapper.compact_header .true_collapse.Mui-expanded>div:nth-child(2)::-webkit-scrollbar-track) {
    background-color: transparent;
}

:global(.true_collapse_wrapper.compact_header .true_collapse.Mui-expanded>div:nth-child(2)::-webkit-scrollbar-thumb) {
    background-color: var(--t-secondary-background);
    border-radius: 5px;
}

:global(.true_collapse_wrapper.compact_header .true_collapse.Mui-expanded>div:nth-child(2)::-webkit-scrollbar-thumb:hover) {
    background-color: var(--t-secondary-background);
}

/* /////////////////////// SCROLL BAR */
/* .true_table_wrapper, .true_table_body, .virtual-body {
      "&::-webkit-scrollbar": {
        width: var(--t-scrollbar-width),
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme?.SECONDARY_BACKGROUND,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme?.SECONDARY_BACKGROUND,
      },
    }, */