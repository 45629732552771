:root {
  --t-primary: "white";
  --t-primary-10: "white";
  --t-primary-20: "white";
  --t-primary-30: "white";
  --t-primary-40: "white";
  --t-primary-50: "white";
  --t-secondary: "white";
  --t-primary-background: "white";
  --t-secondary-background: "white";
  --t-secondary-background-10: "white";
  --t-secondary-background-20: "white";
  --t-secondary-background-30: "white";
  --t-secondary-background-40: "white";
  --t-secondary-background-50: "white";
  --t-contrast-background: "white";
  --t-selected-row-background: "white";
  --t-text-color: "white";
  --t-secondary-text-color: "white";
  --t-contrast-text-color: "white";
  --t-input-text-color: "white";
  --t-border-color: "white";
  --t-border-color-50: "white";
  --t-border-modal-color: "white";
  --t-danger: "white";
  --t-warning: "white";
  --t-success: "white";
  --t-icon-path-collapsed: "white";
  --t-icon-path-expanded: "white";
  --t-heading-5-size: "24px";
  --t-title-size: "16px";
  --t-small-title-size: "12px";
  --t-white: "white";
  --t-scrollbar-width: "10px";
}
