.siteNotification{
    width: 92vw;
    display: flex;
    flex-direction: row;
}

@media (max-width: 1350px) {
    .siteNotification{
        width: 85vw;
    }
}

@media (max-width: 750px) {
    .siteNotification{
        width: 75vw;
    }
}

@media (max-width: 450px) {
    .siteNotification{
        width: 100%;
    }
}