:global(.true_dialog_confirmation) {
  width: 450px;
  margin: 0 auto;
}

:global(
    .true_dialog_confirmation
      #dialogConfirmationDescription
      .true_message_content
  ) {
  overflow-wrap: break-word;
}

:global(
    .true_dialog_confirmation
      #dialogConfirmationDescription
      .true_dialog_content
  ) {
  width: 100%;
  justify-content: center;
}

:global(.true_dialog_confirmation .true_ribbon_node_container) {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

:global(
    .true_dialog_confirmation .true_ribbon_node_container .true_ribbon_node
  ) {
  flex-grow: 1;
}

:global(.true_dialog_paper_container) {
  background-color: var(--t-primary-background);
}

:global(.true_dialog_paper_container p) {
  color: var(--t-text-color);
}
