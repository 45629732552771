.landing_login_program_list_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

:global(div[class*="landing_login_program_list_container"] >*) {
    width: 100%;
}

.program_customer_lists_container {
    height: 100%;
    overflow: auto;
}

:global(div[class*="program_customer_lists_container"]::-webkit-scrollbar) {
    width: var(--t-scrollbar-width);
}

:global(div[class*="program_customer_lists_container"]::-webkit-scrollbar-track) {
    display: none;
}

:global(div[class*="program_customer_lists_container"]::-webkit-scrollbar-thumb) {
    background-color: var(--t-secondary-background);
    border-radius: 5px;
}

:global(div[class*="program_customer_lists_container"]::-webkit-scrollbar-thumb:hover) {
    background-color: var(--t-secondary-background);
}