.claim_landing_body_wrapper {
    height: 100%;
    overflow-y: auto;
    padding-top: 16px;
}

.claim_landing_body_row {
    min-height: 100%;
}

.claim_body_left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.claim_body_right {
    min-height: 100%;
}