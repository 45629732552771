:global(.true_sub_side_nav) {
  height: 100%;
  background-color: var(--t-secondary-background-50) !important;
}

:global(.true_sub_side_nav .MuiButtonBase-root) {
  height: 36px;
}

:global(.true_sub_side_nav .true_parent_container) {
  color: var(--t-text-color);
  background-color: var(--t-secondary-background-30) !important;
}

:global(.true_sub_side_nav .true_parent_container span) {
  font-size: 14px;
}

:global(.true_sub_side_nav .true_parent_container .MuiSvgIcon-root) {
  margin-left: 24px;
  color: var(--t-text-color);
}

:global(.true_sub_side_nav .true_parent_container .true_subside_nav_arrow) {
  font-size: 1.5rem !important;
}

:global(
    .true_sub_side_nav .true_parent_container.true_subside_nav_parent_selected
  ) {
  background-color: var(--t-secondary-background) !important;
}

:global(.true_sub_side_nav .true_subside_nav_children_container) {
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  background-color: var(--t-primary-background) !important;
}

:global(
    .true_sub_side_nav
      .true_subside_nav_children_container
      .true_subside_nav_child_item
  ) {
  background-color: var(--t-secondary-background-20) !important;
}

:global(
    .true_sub_side_nav
      .true_subside_nav_children_container
      .true_subside_nav_child_item
      .true_subside_nav_child_text
  ) {
  text-decoration: inherit;
  padding-left: 15px;
  color: var(--t-text-color);
}

:global(
    .true_sub_side_nav
      .true_subside_nav_children_container
      .true_subside_nav_child_item
      .true_subside_nav_child_text
      > span
  ) {
  font-size: 14px;
}

:global(
    .true_sub_side_nav
      .true_subside_nav_children_container
      .true_subside_nav_child_item.true_subside_nav_child_selected
      .true_subside_nav_child_text
  ) {
  color: var(--t-secondary-text-color);
}

:global(
    .true_sub_side_nav
      .true_subside_nav_children_container
      .true_subside_nav_child_item.true_subside_nav_child_selected
      .true_subside_nav_child_text
      > span
  ) {
  font-weight: 700 !important;
}

:global(
    .true_sub_side_nav
      .true_subside_nav_children_container.true_subside_nav_parent_selected
  ) {
  max-height: 500px;
}
