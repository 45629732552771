#claims_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
}

#left_claims_wrapper {
  min-width: 190px;
}

#right_claims_wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  padding: 15px;
  width: 100%;
}

.claims_body_container {
  height: 100%;
  overflow: hidden;
  display: flex;
  width: 100%;
}

#claims_main_container {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.claims_body_wrapper_container {
  width: 100%;
}
